const translate = {
	messages: {
		ru: {
			'Осматривайте': 'Осматривайте',
			'и': 'и',
			'демонстрируйте': 'демонстрируйте',
			'еще до звонка': 'еще до звонка',
			'Проверьте, или действительно авто такое же, как на фото': 'Проверьте, или действительно авто такое же, как на фото',
			'Осмотрите авто подробнее с его страницы в приложении AUTO.RIA. Вы&nbsp;увидите авто, каким оно есть — с разных ракурсов, изнутри и сможете понять, или авто точно в наличии. Все, чтобы ваше решение о выборе авто было быстрым и взвешенным.': 'Осмотрите авто подробнее с его страницы в приложении AUTO.RIA. Вы&nbsp;увидите авто, каким оно есть — с разных ракурсов, изнутри и сможете понять, или авто точно в наличии. Все, чтобы ваше решение о выборе авто было быстрым и взвешенным.',
			'Создавайте видео истории об авто, чтобы заинтересовать миллионы покупателей': 'Создавайте видео истории об авто, чтобы заинтересовать миллионы покупателей',
			'Видеосообщения в вашем предложении поможет покупателям лучше увидеть особенности авто, а вам — получать звонки от уже заинтересованных и продать быстрее.': 'Видеосообщения в вашем предложении поможет покупателям лучше увидеть особенности авто, а вам — получать звонки от уже заинтересованных и продать быстрее.',
			'Снимайте видео удобно из приложения AUTO.RIA': 'Снимайте видео удобно из приложения AUTO.RIA',
			'Жмите «Записать видеосообщение» при добавлении или редактировании предложения.': 'Жмите «Записать видеосообщение» при добавлении или редактировании предложения.',
			'Покажите преимущества авто с разных ракурсов, снаружи и изнутри и расскажите в видео все, что говорите во время звонка.': 'Покажите преимущества авто с разных ракурсов, снаружи и изнутри и расскажите в видео все, что говорите во время звонка.',
			'Легко смонтируйте отснятое из приложения. Готовое видео загрузится сразу в объявление без необходимости сохранять на смартфоне или других сервисах.': 'Легко смонтируйте отснятое из приложения. Готовое видео загрузится сразу в объявление без необходимости сохранять на смартфоне или других сервисах.',
			'Видеосообщения, аудио и видеозвонки — все самое интересное в приложении AUTO.RIA': 'Видеосообщения, аудио и видеозвонки — все самое интересное в приложении AUTO.RIA',
			'Загрузите, чтобы воспользоваться новыми возможностями.': 'Загрузите, чтобы воспользоваться новыми возможностями.',
			'Истории про авто,': 'Истории про авто,',
			'что продают': 'что продают',
			'вместо вас': 'вместо вас',
			'круглосуточно': 'круглосуточно',
			'Начните разговор с покупателем еще до звонка — со страницы вашего авто': 'Начните разговор с покупателем еще до звонка — со страницы вашего авто',
			'Видеосообщения раскроют преимущества авто лучше, чем десятки фотографий. С ними покупатели быстрее поймут, что ваши авто достойны внимания, и вы быстрее получите звонки от максимально заинтересованных.': 'Видеосообщения раскроют преимущества авто лучше, чем десятки фотографий. С ними покупатели быстрее поймут, что ваши авто достойны внимания, и вы быстрее получите звонки от максимально заинтересованных.',
			'AUTO.RIA – Видеосообщения и истории про авто': 'AUTO.RIA – Видеосообщения и истории про авто',
			'Расскажите в видео все, что бы вы сказали покупателю во время звонка и покажите преимущества авто с разных ракурсов, снаружи и изнутри.': 'Расскажите в видео все, что бы вы сказали покупателю во время звонка и покажите преимущества авто с разных ракурсов, снаружи и изнутри.',
			'Щоб завантажити додаток, виберіть платформу вашого смартфону, отримайте посилання в SMS або проскануйте': 'Чтобы загрузить приложение, выберите платформу вашего смартфона, получите ссылку в SMS или просканируйте',
		},
		uk: {
			'Осматривайте': 'Оглядайте',
			'и': 'та',
			'демонстрируйте': 'демонструйте',
			'еще до звонка': 'до здійснення дзвінка',
			'Проверьте, или действительно авто такое же, как на фото': 'Перевірте, чи авто справді таке, як на фото',
			'Осмотрите авто подробнее с его страницы в приложении AUTO.RIA. Вы&nbsp;увидите авто, каким оно есть — с разных ракурсов, изнутри и сможете понять, или авто точно в наличии. Все, чтобы ваше решение о выборе авто было быстрым и взвешенным.': 'Огляньте авто детальніше з його сторінки у додатку AUTO.RIA. Ви&nbsp;побачите авто, яким воно є — з різних ракурсів, зсередини та зможете зрозуміти, чи точно авто в наявності.  Все, щоб ваше рішення про вибір авто було швидким та зваженим.',
			'Создавайте видео истории об авто, чтобы заинтересовать миллионы покупателей': 'Створюйте відео історії про авто, щоб зацікавити мільйони покупців',
			'Видеосообщения в вашем предложении поможет покупателям лучше увидеть особенности авто, а вам — получать звонки от уже заинтересованных и продать быстрее.': 'Відеоповідомлення у вашій пропозиції допоможе покупцям краще побачити особливості авто, а вам — отримувати дзвінки від вже зацікавлених і швидше продати. ',
			'Снимайте видео удобно из приложения AUTO.RIA': 'Знімайте відео зручно з додатку AUTO.RIA',
			'Жмите «Записать видеосообщение» при добавлении или редактировании предложения.': 'Тисніть «Записати відеоповідомлення» при додаванні чи редагуванні пропозиції.',
			'Покажите преимущества авто с разных ракурсов, снаружи и изнутри и расскажите в видео все, что говорите во время звонка.': 'Покажіть переваги авто з різних ракурсів, ззовні та зсередини та розкажіть у відео все, що говорите під час дзвінка. ',
			'Легко смонтируйте отснятое из приложения. Готовое видео загрузится сразу в объявление без необходимости сохранять на смартфоне или других сервисах.': 'Легко змонтуйте відзняте з додатку. Готове відео завантажиться одразу до пропозиції, без потреби зберігати на смартфоні чи інших сервісах.',
			'Видеосообщения, аудио и видеозвонки — все самое интересное в приложении AUTO.RIA': 'Відеоповідомлення, аудіо та відеодзвінки — усе найцікавіше у додатку AUTO.RIA',
			'Загрузите, чтобы воспользоваться новыми возможностями.': 'Завантажте, щоб скористатися новими можливостями.',
			'Истории про авто,': 'Історії про авто,',
			'что продают': 'що продають',
			'вместо вас': 'за вас',
			'круглосуточно': 'цілодобово',
			'Начните разговор с покупателем еще до звонка — со страницы вашего авто': 'Почніть розмову з покупцем ще до дзвінка — зі сторінки вашого авто ',
			'Видеосообщения раскроют преимущества авто лучше, чем десятки фотографий. С ними покупатели быстрее поймут, что ваши авто достойны внимания, и вы быстрее получите звонки от максимально заинтересованных.': 'Відеоповідомлення розкриє переваги авто краще, ніж десятки фотографій. З ними покупці швидше зрозуміють, що ваші авто варті уваги, і ви швидше отримаєте дзвінки від максимально зацікавлених.',
			'AUTO.RIA – Видеосообщения и истории про авто': 'AUTO.RIA – Відеоповідомлення і історії про авто',
			'Расскажите в видео все, что бы вы сказали покупателю во время звонка и покажите преимущества авто с разных ракурсов, снаружи и изнутри.': 'Розкажіть у відео все, що б ви хотіли сказати покупцю під час дзвінка, та покажіть переваги авто з різних ракурсів, ззовні та зсередини.',
			'Щоб завантажити додаток, виберіть платформу вашого смартфону, отримайте посилання в SMS або проскануйте': 'Щоб завантажити додаток, виберіть платформу вашого смартфону, отримайте посилання в SMS або проскануйте',
		}
	}
};
export default translate;
