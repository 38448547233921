import appButtons from './components/AppButtons/index.vue';
import sendSms from './components/SendSms/index.vue';
import { mapActions, mapGetters } from 'vuex';
const {get} = require('../../helpers/cookie');

export default {
	name: 'VideoLanding',
	data: function () {
		return {
			title: this.lang === 4 ? 'AUTO.RIA – Відеоповідомлення і історії про авто' : 'AUTO.RIA – Видеосообщения и истории про авто',
			description: this.lang === 4 ? 'Перевірте чи авто дійсно таке, як в оголошенні. Ви побачите авто яким воно є - з різних ракурсів, зсередини і переконайтеся, що авто в наявності.' : 'Проверьте или авто действительно такое, как в объявлении. Вы увидите авто каким оно есть - с разных ракурсов, изнутри и убедитесь, что авто в наличии.',
			mobileOs: '',
			setStickyClass: false,
		};
	},
	i18n: require('./i18n').default,
	methods: {
		...mapActions({
			loadSegment: 'others/loadSegment',
			getUserData: 'Common/userData'
		}),
		removeStickyFromBtn() {
			const IOW = require('../../helpers/IntersectionObserver_screen__01');
			IOW.add(this.$refs.sticky, (entry = {}) => {
				if (entry.isIntersecting) {
					this.setStickyClass = true;
				} else {
					this.setStickyClass = false;
				}
			});
		},
	},
	computed: {
		...mapGetters({
			segment: 'others/segment',
			lang: 'lang/id',
			isWebView: 'shared/device/isWebView',
		}),
		isSmb() {
			return Boolean(this.segment && this.segment.match(/b2c|smb/i));
		},
	},
	mounted() {
		this.loadSegment();
		this.removeStickyFromBtn();
		this.getUserData().then(userData => {
			const { userId = 0, webClientId = 0 } = userData;
			const obj = {
				event_id: this.isSmb ? 679 : 680,
				screentype: this.isDesktop ? 1 : 2,
				web_id: webClientId,
				user_id: userId,
				owner_id: userId,
			};
			if (this.isSmb) {
				Object.assign(obj, {user_b2b: userId});
			} else {
				Object.assign(obj, {wall_owner_id: userId});
			}
			this._slonik(obj);
		});
	},
	components: {appButtons, sendSms},
	metaInfo() {
		return {
			title: this.title,
			meta: [
				{name: 'description', content: this.description},
				{property: 'og:site_name', content: 'AUTO.RIA'},
				{property: 'og:description', content: this.description},
				{property: 'og:image', content: 'https://auto.ria.com/share_16.png'},
			],
			link: [
				{rel: 'alternate', hreflang: 'ru-UA', href: 'https://auto.ria.com/mms/'},
				{rel: 'alternate', hreflang: 'uk-UA', href: 'https://auto.ria.com/uk/mms/'},
				{rel: 'alternate', hreflang: 'ru', href: 'https://auto.ria.com/mms/'},
				{rel: 'alternate', hreflang: 'uk', href: 'https://auto.ria.com/uk/mms/'},
				{rel: 'alternate', hreflang: 'x-default', href: 'https://auto.ria.com/mms/'},
				{rel: 'canonical', href: 'https://auto.ria.com/mms/'}
			]
		};
	}
};
